

















































































































































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { loadWidget } from '@/utils/helpers';
import { Getter, namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { IUser } from '@/types/types';
import { Watch } from 'vue-property-decorator';
const auth = namespace('auth');
extend('required', {
  ...required,
  message: `Field is required`,
});
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    ValidationProvider,
    ValidationObserver,
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @auth.Getter('getUserDetails') details!: IUser;

  activePicker = '';
  date: string | null = null;
  menu = false;
  previewUrl: ArrayBuffer | null | undefined | string = '';

  email = '';
  user: IUser & { dateOfBirth: string } = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    username: '',
    dateOfBirth: '',
  };
  file: File | null = null;

  @Watch('details')
  onDetailsChange(details: IUser & { dateOfBirth: string }): void {
    this.user = { ...details };
    this.date = this.user.dateOfBirth;
    this.previewUrl = this.user.photo;
  }

  @Watch('menu')
  onMenuChange(state: boolean): void {
    state && setTimeout(() => (this.activePicker = 'YEAR'));
  }

  photo(file: File): void {
    if (file) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        this.previewUrl = ev.target?.result;
      };
      reader.readAsDataURL(file);
      this.file = file;
      // getting  the form data for the app
    } else {
      this.previewUrl = null;
    }
  }

  updateProfile(): void {
    const { firstName, lastName, phoneNumber, username } = this.user;
    const user = {
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth: this.date,
    };
    this.$store.dispatch('auth/updateProfile', user);
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file as File, this.file?.name);
      this.$store.dispatch('auth/uploadPhoto', formData);
    }
  }

  save(date: string): void {
    (this.$refs.menu as HTMLElement & { save: (date: string) => void }).save(
      date
    );
  }
  created(): void {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('checkCreditBalance');
  }
}
